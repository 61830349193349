import { gettext } from "#js/components/i18n"
import { fetchJSON } from "#js/components/http"
import { offset } from "#dependencies/@floating-ui/dom"
import { createTour, getButton, MODAL_PADDING } from "#js/integrations/tours/tourUtils"

const FLOATING_UI_OFFSET = 50

/**
 * Determine if the given path is a home path.
 * @param {string} path - The path to check.
 * @returns {boolean} true if the given path is a home path
 * [
 *  '/',               // should match
 *  '/en/',            // should match
 *  '/de/',            // should match
 *  '/fr/',            // should match
 *  '/zh-hans/',       // should match
 *  '/it/',            // should match
 *  '/es-es/',         // should match
 *  '/pt-br/',         // should match
 *  '/whatever/',      // should not match
 *  '/en/something',   // should not match
 *  '/de/extra',       // should not match
 *  '/fr/extra/',      // should not match
 *  '/zh-hans/extra',  // should not match
 * ];
 */
function isHomePath(path) {
  const homePathRegex = /^\/([a-z]{2}(?:-[a-z]{2,4})?\/)?$/
  return homePathRegex.test(path)
}

function getSteps(tour, settings) {
  return [
    {
      id: "welcome",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "<b>Welcome to voiio!</b><br><br>" +
          "I am your assistant and will introduce you to the voiio platform!",
      ),
      buttons: [
        getButton(tour, "cancel"),
        getButton(tour, "start"),
      ],
    },
    {
      id: "core-topics",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Our 3 categories are the <b>foundation of voiio</b>.<br><br>" +
          "They improve the work-life balance, promote health," +
          " strengthen the team culture and offer help in challenging life situations.",
      ),
      attachTo: {
        element: '[data-tour="core-topics"]',
        on: "top",
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "core-topic",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Here you can see all topics that belong to a certain <b>category</b>.",
      ),
      attachTo: {
        element: '[data-tour="core-topic"]',
        on: "right",
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
      floatingUIOptions: {
        middleware: [offset(FLOATING_UI_OFFSET)],
      },
    },
    {
      id: "topic",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Within an offer category you can choose a <b>specific topic</b>" +
          " that interests you.<br><br><b>Please click on the highlighted topic</b>",
      ),
      attachTo: {
        element: '[data-tour="topic"]',
        on: "right",
      },
      canClickTarget: true,
      modalOverlayOpeningPadding: MODAL_PADDING.MEDIUM,
      floatingUIOptions: {
        middleware: [offset(FLOATING_UI_OFFSET)],
      },
    },
    {
      id: "topic-offer-list",
      canShow: globalThis.location.pathname.endsWith(
        `/topics/${settings.core_topic_slug}/`,
      ),
      text: gettext(
        "Here you can explore all <b>offers</b> for the topic that interests you.",
      ),
      attachTo: {
        element: '[data-tour="topic-offer-list"]',
        on: "top",
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "nav-bar-program",
      canShow: globalThis.location.pathname.endsWith(
        `/topics/${settings.core_topic_slug}/`,
      ),
      text: gettext(
        "The <b>Program</b> shows you offers in a calendar view." +
          "<br><br><b>Please click on the Program tab</b>",
      ),
      attachTo: {
        element: '[data-tour="nav-bar-program"]',
        on: "bottom",
      },
    },
    {
      id: "program-info",
      canShow: globalThis.location.pathname.endsWith("/program/") &&
        !settings.steps_shown.includes("program-info"),
      text: gettext(
        "In the program you will find all <b>" +
          "offers that start soon and have been recommended for you</b>." +
          "<br><br>Perfect for booking an offer that fits your schedule.",
      ),
      buttons: [getButton(tour, "cancel"), getButton(tour, "next")],
    },
    {
      id: "language-choice",
      canShow: (
        globalThis.location.pathname.endsWith("/program/") &&
        globalThis.language === "de" &&
        !settings.steps_shown.includes("language-choice")
      ),
      text: gettext(
        "voiio offers a complete platform experience in German and English." +
          "<br><br>Please click on the <b>language selector</b> and choose <b>English</b> to continue.",
      ),
      attachTo: {
        element: '[data-tour="language-choice"]',
        on: "bottom-end",
      },
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "program-info-en",
      canShow: globalThis.location.pathname.endsWith("/program/") &&
        globalThis.language === "en" &&
        !settings.steps_shown.includes("program-info-en") &&
        settings.steps_shown.includes("language-choice"),
      text:
        "<b>We will only show you content that is available in your preferred language.</b>" +
        "<br><br>Wir zeigen Ihnen nur Inhalte an, die in Ihrer bevorzugten Sprache verfügbar sind.",
      buttons: [getButton(tour, "next")],
    },
    {
      id: "switch-language-back",
      canShow: globalThis.location.pathname.endsWith("/program/") &&
        globalThis.language === "en",
      text: '<b>Now please switch the language to "Deutsch".</b>' +
        '<br><br>Bitte wechseln Sie jetzt die Sprache auf "Deutsch".',
      attachTo: {
        element: '[data-tour="language-choice"]',
        on: "bottom",
      },
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "nav-bar-eap",
      canShow: (
        globalThis.location.pathname.endsWith("/program/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "voiio combines thousands of low-threshold services with personalized counselling." +
          '<br><br><b>Please click on the "Counselling" tab for more information.</b>',
      ),
      attachTo: {
        element: '[data-tour="nav-bar-eap"]',
        on: "bottom-end",
      },
    },
    {
      id: "eap-info",
      canShow: (
        globalThis.location.pathname.endsWith("/experts/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "By working with voiio, you give your employees access to a network of more " +
          "than 2000 different experts who can provide personalised advice in every " +
          "situation and phase of life.<br><br>Depending on the package you book with " +
          "voiio, your employees can book counselling sessions either free of charge or " +
          "self-financed.",
      ),
      attachTo: {
        element: '[data-tour="eap-offers"]',
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      scrollTo: true,
      when: {
        show: function () {
          this.getTarget().scrollIntoView()
        },
      },
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "nav-bar-concierge",
      canShow: (
        globalThis.location.pathname.endsWith("/experts/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "You get tailored support for your unique needs." +
          '<br><br><b>Please click on the "Concierge" tab for more information.</b>',
      ),
      attachTo: {
        element: '[data-tour="nav-bar-concierge"]',
        on: "bottom-end",
      },
    },
    {
      id: "concierge-info",
      canShow: (
        globalThis.location.pathname.endsWith("/concierge/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "Our concierge service takes the pressure off you at every stage of your life." +
          "<br><br>From support in finding a childcare place, emergency care to outpatient carers.",
      ),
      attachTo: {
        element: '[data-tour="concierge-offers"]',
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      scrollTo: true,
      when: {
        show: function () {
          this.getTarget().scrollIntoView()
        },
      },
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "nav-bar-profile-menu",
      canShow: (
        globalThis.location.pathname.endsWith("/concierge/") &&
        document.querySelector('[data-tour="nav-bar-dashboard"]') !== null
      ),
      text: gettext(
        "Make your success measurable — with our ongoing usage reporting." +
          " <br> Please <b>click on the round symbol</b> in the menu.",
      ),
      attachTo: {
        element: '[data-tour="mainnav-profile-group"]',
        on: "bottom-end",
      },
      buttons: [],
      canClickTarget: true,
      scrollTo: true,
      advanceOn: { selector: '[data-tour="mainnav-profile-group"]', event: "click" },
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "nav-bar-dashboard",
      canShow: (
        globalThis.location.pathname.endsWith("/concierge/") &&
        document.querySelector('[data-tour="nav-bar-dashboard"]') !== null
      ),
      text: gettext(
        "The last menu item in the list, <b>“Your company”</b>, will take you to reporting.",
      ),
      attachTo: {
        element: '[data-tour="nav-bar-dashboard"]',
        on: "bottom-end",
      },
      buttons: [getButton(tour, "next")],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING.LARGE,
    },
    {
      id: "finish",
      canShow: (
        globalThis.location.pathname.endsWith("/program/") ||
        (
          settings.has_eap_enabled && (
            globalThis.location.pathname.endsWith("/experts/") ||
            globalThis.location.pathname.endsWith("/concierge/")
          )
        )
      ),
      text: gettext(
        "<b>That's it!</b><br><br>" +
          "Now you can explore the rest of the voiio platform yourself, have fun!",
      ),
      buttons: [getButton(tour, "done")],
    },
  ]
}

function updateProductTourConfig(data) {
  const csrftoken = document.querySelector("input[name=csrfmiddlewaretoken]").value

  fetch("/api/product-tour/update/", {
    method: "put",
    body: JSON.stringify(data),
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  })
}

export async function initProductTour() {
  const settings = await fetchJSON("/api/product-tour/settings/")

  if (!settings) {
    console.warn("Failed to fetch product tour settings.")
    return
  }

  if (!settings.should_show_tour) return

  const tour = createTour()

  for (const step of getSteps(tour, settings)) {
    if (step.canShow) {
      tour.addStep(step)
    }
  }

  tour.on("show", (data) => {
    const tourConfig = {
      tour_steps_shown: [data.step.id],
    }

    if (data.step.id !== "welcome") {
      tourConfig.tour_status = "started"
    }

    updateProductTourConfig(tourConfig)

    globalThis.dataLayer.push({
      event: "product_tour_show",
      step_id: data.step.id,
    })
  })

  tour.on("cancel", (data) => {
    updateProductTourConfig({ tour_status: "cancelled" })

    globalThis.dataLayer.push({
      event: "product_tour_cancel",
      step_id: data.tour.currentStep.id,
    })
  })

  tour.on("complete", (data) => {
    if (data.tour.currentStep) {
      updateProductTourConfig({
        tour_steps_shown: [data.tour.currentStep.id],
        tour_status: "finished",
      })

      globalThis.Userback.openSurvey("mWdmIE")

      globalThis.dataLayer.push({
        event: "product_tour_finish",
      })
    }
  })

  tour.start()
}
